<template>
  <div class="explain-box" id="container">
    <img :src="require('@/assets/images/explain.png')" class="img1" />
    <div class="explain-center" id="content">
      <div class="explain-center-left">
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item
            :title="item.name"
            :name="item.value"
            v-for="(item, index) in list"
            :key="index"
          >
            <ul>
              <li
                v-for="(litem, lindex) in item.content"
                :key="`l${lindex}`"
                @click="changeMenuList(index, lindex)"
                :class="{ active: item.state && activeIndex === lindex }"
              >
                {{ litem.name }}
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="explain-center-right" id="explain-center-right" ref="explain-center-right">
        <section>
          <div ref="page-1-1">
            <h5>关于 Blink Focos</h5>
            <h6>Blink Tech是一家怎样的公司？</h6>
            <p>
              Blink
              Tech在2020年成⽴于美国硅⾕，专注于“⼿机智能摄像”功能，基于智能⼿机和AI技术对业余体育运动进⾏智能跟踪拍摄和数据分析。两位创始人均在美国加州大学取得博士学位，并在美国亚马逊尖端硬件实验室、贝尔实验室、清华大学等有超过10年的智能硬件的研发经验，其他核⼼成员均在美国取得硕博⼠学位，或具备多年的相关经验。今年因业务发展，在北京海淀区留学生创业园成立国内公司
              - 深眸科技（北京）公司。
              2021年，公司多次在国内外的创新创业大赛获奖（以下为公司取得的部分荣誉）：
            </p>
            <p>
              1. 国家级创业大赛“创客北京2021”荣获人工智能赛道10强 2.
              获美国斯坦福大学、麻省理工等联合主办的GCEA全球华人创业大赛 -
              “深科技”赛道第三名 3. 入选美国亚马逊云科技(AWS) - 全球“云创计划”
              4. 入选美国CES 2022（全球电子消费大展）- Eureka Park
            </p>
          </div>
          <div ref="page-1-2">
            <h6>Blink Focos是怎样的产品？</h6>
            <p>
              Blink
              Focos是一款为体育爱好者量身打造的智能云台系统。我们希望把手机变成智能摄像师，深度理解足球、篮球等团队比赛场景，帮助用户智能跟踪拍摄和直播比赛。Blink
              Focos可以区分球员和观众，自动跟踪比赛热点，并直播比赛。
            </p>
          </div>
          <div ref="page-1-3">
            <h6>它是如何工作的？</h6>
            <p>
              将Blink Focos云台和⼿机架设在场边的三脚架上，开机、开拍。Blink
              Focos系统⾃动完成比赛的拍摄，您可以同时开启直播，进行现场解说。
            </p>
          </div>
          <div ref="page-1-4">
            <h6>为什么Blink Focos 云台与其他云台的区别?</h6>
            <p>
              Blink
              Focos云台是一款利用最新AI技术和iPhone平台强大的处理能力实现自动跟踪拍摄功能的云台。目前市场上其他手机云台的跟踪功能只能跟踪一个人，并且无法在复杂、快速的比赛场景中进行跟踪拍摄，也不能拍摄远处的人。Blink
              Focos可以跟拍篮球、足球等团队比赛，可以识别30米以外的远距离场景并且自动变焦，
              未来还可以进行多机位的组合拍摄。
            </p>
          </div>
          <div ref="page-1-5">
            <h6>它适用于羽毛球、乒乓球、网球等其他运动吗？</h6>
            <p>
              我们正在开发单人模式。单人模式适用于拍摄羽毛球、网球等活动。您可以在单人模式中拍摄画面中一个或两个运动的人。此外，我们2022年会扩展更多智能运动拍摄模式，届时会有羽毛球、网球、击剑等更多运动模式可选择。敬请期待！
            </p>
          </div>
          <div ref="page-1-6">
            <h6>Blink Focos 什么时候登陆 Android？</h6>
            <p>
              我们预计在 2022 年下半年推出安卓版！
              请关注我们的微信公众号“Blink深眸科技”
              （微信号搜索JustBlinkTech），当 Blink Focos
              推出安卓版本时收到通知！
            </p>
          </div>
        </section>
        <section>
          <div ref="page-2-1">
            <h5>拍摄之前</h5>
            <h6>使用 Blink Focos 需要准备什么？</h6>
            <p>
              您需要自备一台iphone
              XS或更新的苹果手机、运行iOS14以上的系统，购买一个Blink
              Focos云台，选购我们的Blink
              T2三脚架或自备一个能与云台兼容（标准1/4"接口）的三脚架。
            </p>
          </div>
          <div ref="page-2-2">
            <h6>手机和云台耗电情况？</h6>
            <p>
              为获得最佳效果，手机需要20%以上的电量录制三十分钟的视频。
              云台充满电，充至电源灯不再闪烁的情况下，可以工作280分钟。
              亮四盏电源灯的情况下，至少可以工作一整场足球比赛的时间（120分钟）。
            </p>
          </div>
          <div ref="page-2-3">
            <h6>录制视频需要多少存储空间？</h6>
            <p>
              录制10分钟1080p(默认)的视频，大概需要200MB的空间。
              录制10分钟720p(可选)的视频，大概需要40MB的空间。
            </p>
          </div>
          <div ref="page-2-4">
            <h6>云台如何水平校准？</h6>
            <p>此款云台不需要水平校准。请尽量把手机放在手机夹的居中位置。</p>
          </div>
          <div ref="page-2-5">
            <h6>如何切换横竖拍？</h6>
            <p>单击电源键，可以切换横竖拍。目前，Blink Focos只支持横屏拍摄。</p>
          </div>
          <div ref="page-2-6">
            <h6>云台控制板上面的按键下方的图标都是什么意思？</h6>
            <p>
              模式键（M键）和电源键下方的图标是给稳定器功能做的标记，在使用Blink
              Focos App期间不需要使用这些图标。
            </p>
          </div>
          <div ref="page-2-7">
            <h6>Blink Focos云台可以用作手机稳定器使用吗？</h6>
            <p>
              可以！您需要单独从App Store下载Gimbal Pro
              App，配合云台，即可作为手持手机稳定器使用，三轴稳定，四种跟随模式。具体操作方法请参考：（新宇请插入Gimbal
              Pro说明书）
            </p>
          </div>
          <div ref="page-2-8">
            <h6>Blink Focos App可以使用前置摄像头拍摄吗？</h6>
            <p>
              由于前置摄像头的画质和变焦功能有限，我们暂不支持前置摄像头。我们会在2022年开启前置摄像头功能，敬请期待！
            </p>
          </div>
          <div ref="page-2-9">
            <h6>除了摄像，有拍照功能吗？</h6>
            <p>
              Blink Focos
              App是自动摄像系统，暂时不支持拍照。拍照功能将于2022年上线，敬请期待！
            </p>
          </div>
          <div ref="page-2-10">
            <h6>拍摄时可以自动变焦吗？</h6>
            <p>可以！请在拍摄页面设置选项中，选择开启自动变焦。</p>
          </div>
          <div ref="page-2-11">
            <h6>给云台充电，有什么注意事项么?</h6>
            <p>
              请使用推荐的5V2A充电适配器，如果使用5V1A的适配器，充电时间会很长。如果使用超过5V2A的适配器，可能会造成损坏。
            </p>
          </div>
        </section>
        <section>
          <div ref="page-3-1">
            <h5>蓝牙问题</h5>
            <h6>连接设备时，为什么设备列表里是空的？</h6>
            <p>
              请检查手机系统设置里的蓝牙连接情况。如果手机蓝牙设置显示已经连接云台的蓝牙，请选择“忽略此设备”可断开该蓝牙连接。断开蓝牙后再打开Blink
              Focos App来连接。或者重置Blink
              Focos云台蓝牙，同时按下快门键和电源键，长按三秒钟，云台蓝牙重置后，Blink
              Focos App 设备列表中会出现设备蓝牙名称。
            </p>
          </div>
          <div ref="page-3-2">
            <h6>
              手机的蓝牙设置中可以看到设备蓝牙和手机已经连接了，但是App不能开始录制，怎么办
            </h6>
            <p>
              请退出Blink Focos
              App，重置云台蓝牙（同时按下快门键和电源键，长按三秒钟），重新开启Blink
              Focos App，点击开始拍摄，即可重新连接云台蓝牙设备。
            </p>
          </div>
        </section>
        <section>
          <div ref="page-4-1">
            <h5>拍摄期间</h5>
            <h6>如果我在拍摄过程中接到电话怎么办？</h6>
            <p>拍摄不会停止，但是直播会停止。</p>
          </div>
          <div ref="page-4-2">
            <h6>自动拍摄过程中，我可以手动干预拍摄的方向吗？</h6>
            <p>
              可以。您可以通过方向键调整手机的角度。手动调整时，云台会停止自动跟踪。在您松开方向键控制后，云台会恢复自动跟踪。请不要直接用手强行转动云台，这样可能会造成云台损坏。
            </p>
          </div>
          <div ref="page-4-3">
            <h6>我的手机拍摄时不工作了，怎么办？</h6>
            <p>
              很抱歉看到此种情形的发生。最简单的恢复办法，就是将app强行退出，重新启动，并重新与云台连接好，重新开始拍摄。
            </p>
          </div>
          <div ref="page-4-4">
            <h6>
              我的手机怎么只能向一边转动。向另一边转的时候，好像卡在那里动不了。
            </h6>
            <p>
              请保证开机后，手机镜头的初始方向，也就是云台快门键的方向，和球场的中线一致。
            </p>
          </div>
          <div ref="page-4-5">
            <h6>云台是跟踪某一个球员么？</h6>
            <p>
              云台并不是近跟踪某位具体的球员，而是有一套复杂的算法，根据场上的形势判断，从而跟踪踢球或打球的热点区域。从实现的效果上来说，更接近于“跟踪球”。
            </p>
          </div>
          <div ref="page-4-6">
            <h6>云台的跟踪原理是什么？是跟踪球么？</h6>
            <p>
              Blink
              Focos通过人工智能算法，判断球场局势，跟踪拍摄球场热点。会尽量像实况转播那样，实现跟踪球的拍摄效果。
            </p>
          </div>
          <div ref="page-4-7">
            <h6>会不会跟丢？</h6>
            <p>
              我们在技术上还不能保证永远不丢焦点，但是跟踪准确率至少可以达到85%，就是说您整场比赛拍下来，会有一小部分时间它没跟住球，但它会实时进行调整，丢了以后还会找回来的。
            </p>
          </div>
          <div ref="page-4-8">
            <h6>踢球时，开大脚能跟上？打篮球时，长传球能否跟上？</h6>
            <p>
              抱歉，跟不上。由于手机摄像头的分辨率，AI的处理速度和云台转速的限制，足球中的开大脚，篮球中的长传球，目前Focos还无法准确跟上。但云台会随着球员的运动，逐步对准和找到场景。
            </p>
          </div>
          <div ref="page-4-9">
            <h6>我手机快没电了，能想办法让它多工作一会么？</h6>
            <p>您可以手动调低屏幕亮度，减少手机电量损耗。</p>
          </div>
          <div ref="page-4-10">
            <h6>视频的分辨率可以设置吗？</h6>
            <p>
              对于iPhone11及更新的手机（iPhone 11全系列，iPhone 12全系列，iPhone
              13全系列）系统默认的视频分辨率是1080p，但用户可选择720p。由于硬件处理速度的限制，iPhone
              11之前的手机型号（如iphone SE、XS等）只支持720p的视频分辨率。
            </p>
          </div>
          <div ref="page-4-11">
            <h6>我的视频是实时处理的吗？</h6>
            <p>是的。Blink Focos App 会实时处理镜头画面，完成跟踪和拍摄。</p>
          </div>
          <div ref="page-4-12">
            <h6>除了拍视频，我还可以干什么？</h6>
            <p>
              您在拍摄比赛的同时，还可以开启直播。具体方法可以参考使用说明。（此处可插入链接）
            </p>
          </div>
          <div ref="page-4-13">
            <h6>我买了两套设备，但是两个云台都连到同一个手机了，怎么办啊？</h6>
            <p>
              您可以同时按住准备连接到另外一台手机的云台的快门键和电源键3秒钟，让该云台的蓝牙重置，该云台就拥有了一个新的蓝牙ID，从而断开与原手机的蓝牙连接，就可以连接到新的手机了。
            </p>
          </div>
        </section>
        <section>
          <div ref="page-5-1">
            <h5>直播</h5>
            <h6>如何开启直播？</h6>
            <p>
              开启直播需要在App中注册账号并且登录账号后使用。在拍摄过程中，点击屏幕右上角的“开启直播”，即可开启直播。您可以设置自己的直播标题。
            </p>
          </div>
          <div ref="page-5-2">
            <h6>如何观看别人的直播？</h6>
            <p>
              如果您已经下载了Blink Focos App,
              请在App最下面的导航栏中选择“直播”，进入直播列表，搜索或者选择您想看的直播频道即可收看。
            </p>
          </div>
          <div ref="page-5-3">
            <h6>我有一个功能请求！我怎么联系你？</h6>
            <p>
              我们非常感谢您的意见。您可以通过邮箱support@blinktech.us联系我们。感谢您帮助我们使Blink
              Focos变得更好！
            </p>
          </div>
          <div ref="page-5-4">
            <h6>帮助，我的问题没有在这里列出！</h6>
            <p>
              请通过邮箱support@blinktech.us与我们联系，我们将帮助解决任何问题！
            </p>
          </div>
        </section>
        <section>
          <div ref="page-6-1">
            <h5>拍摄后</h5>
            <h6>视频存储在哪里？</h6>
            <p>
              视频存在手机的系统相册里，也存在app里。还可以手动保存到云盘中。
            </p>
          </div>
          <div ref="page-6-2">
            <h6>App本地视频和iphone 相册中的视频会占用双倍内存吗？</h6>
            <p>
              会。视频上传到云端后，您可以手动删除本地视频，释放更多手机存储空间。
            </p>
          </div>
          <div ref="page-6-3">
            <h6>App里的本地视频如何上传云端？</h6>
            <p>
              打开Blink Focos
              App，进入云管理页面，点击右上角的文件夹图标，选择您想上传的视频，点击屏幕下方的“上传”图标，即可将本地视频上传到云端。
            </p>
          </div>
          <div ref="page-6-4">
            <h6>我可以把手机相册的视频上传到Blink Focos云端吗？</h6>
            <p>不可以。</p>
          </div>
          <div ref="page-6-5">
            <h6>我可以与没有 Blink Focos 的朋友分享我的视频吗？</h6>
            <p>
              您可以把Blink
              Focos云端的视频分享给微信好友。在“云管理”页面，打开“云盘”，点击右上角文件夹图标，选择您希望分享的视频，点击屏幕下方的“分享”图标，即可分享视频给微信好友。
            </p>
          </div>
          <div ref="page-6-6">
            <h6>Blink Focos云盘可以在电脑上打开吗？</h6>
            <p>可以。</p>
          </div>
          <div ref="page-6-7">
            <h6>如何将视频从网盘下载到本地手机？</h6>
            <p>
              在“云管理”页面，打开“云盘”，点击右上角文件夹图标，选择您希望下载的视频，点击屏幕下方的“下载到本地”图标，即可下载视频到本地。
            </p>
          </div>
          <div ref="page-6-8">
            <h6>如何释放手机上的空间？</h6>
            <p>
              视频上传到云端后，您可以手动删除本地视频，释放更多手机存储空间。
            </p>
          </div>
          <div ref="page-6-9">
            <h6>我的视频需要很长时间都没有上传到云端，这是怎么回事？</h6>
            <p>需要您的手机app在打开置于前台的状态下，才能快速上传数据。</p>
          </div>
          <div ref="page-6-10">
            <h6>我有一个功能请求！我怎么联系你？</h6>
            <p>
              我们非常感谢您的意见。您可以通过邮箱support@blinktech.us联系我们。
              感谢您帮助我们使Blink Focos变得更好！
            </p>
          </div>
        </section>
        <section>
          <div ref="page-7-1">
            <h5>隐私</h5>
            <h6>上传到云端的视频会被泄露隐私吗？</h6>
            <p>
              我们严格遵守国家关于数据安全和隐私保护的相关法律和法规，如《中华人民共和国数据安全法》和《个人信息保护法》等。并把保障客户数据安全和隐私作为我们服务客户的宗旨和最高优先级，请您放心。
            </p>
          </div>
          <div ref="page-7-2">
            <h6>其他用户可以看到我的个人资料吗？</h6>
            <p>其他人看不到你的任何资料，每人只能看到自己的视频和资料。</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: "1",
      activeIndex: 0,
      list: [
        {
          name: "关于 Blink Focos",
          value: 1,
          state: true,
          content: [
            {
              name: "Blink Tech 公司",
            },
            {
              name: "Blink Tech 产品",
            },
            {
              name: "它是如何工作的",
            },
            {
              name: "它与其他云台的区别",
            },
            {
              name: "它能否适用于其他运动",
            },
            {
              name: "它什么时候登陆Android",
            },
          ],
        },
        {
          name: "拍摄之前",
          value: 2,
          state: false,
          content: [
            {
              name: "需要准备什么",
            },
            {
              name: "手机和云台耗电情况",
            },
            {
              name: "录制视频需要多少存储空间",
            },
            {
              name: "它如何水平校准",
            },
            {
              name: "如何切换横竖拍",
            },
            {
              name: "它控制板上下方图标的意思",
            },
            {
              name: "它可以用作手机稳定器吗",
            },
            {
              name: "App可以使用前置摄像头拍摄吗",
            },
            {
              name: "有拍照功能吗",
            },
            {
              name: "拍摄时可以自动变焦",
            },
            {
              name: "它充电的注意事项",
            },
          ],
        },
        {
          name: "蓝牙问题",
          value: 3,
          state: false,
          content: [
            {
              name: "连接设备时，设备列表里是空的",
            },
            {
              name: "设备连接了，App不能开始录制",
            },
          ],
        },
        {
          name: "拍摄期间",
          value: 4,
          state: false,
          content: [
            {
              name: "拍摄过程中接到电话怎么办",
            },
            {
              name: "自动拍摄过程中调整拍摄方向",
            },
            {
              name: "手机拍摄时不工作了",
            },
            {
              name: "我的手机向另一边转时卡了",
            },
            {
              name: "它是跟踪某一个球员么",
            },
            {
              name: "它的跟踪原理",
            },
            {
              name: "它会不会跟丢",
            },
            {
              name: "设备是否可以跟上",
            },
            {
              name: "手机快没电可以多工作一会么",
            },
            {
              name: "设置视频分辨率",
            },
            {
              name: "视频实时处理",
            },
            {
              name: "除了拍视频，还能做什么",
            },
            {
              name: "两套设备连到同一个手机",
            },
          ],
        },
        {
          name: "直播",
          value: 5,
          state: false,
          content: [
            {
              name: "如何开启直播",
            },
            {
              name: "如何观看直播",
            },
            {
              name: "联系我们",
            },
            {
              name: "帮助",
            },
          ],
        },
        {
          name: "拍摄后",
          value: 6,
          state: false,
          content: [
            {
              name: "视频存储",
            },
            {
              name: "内存问题",
            },
            {
              name: "本地视频上传云端",
            },
            {
              name: "手机视频上传云端",
            },
            {
              name: "分享视频",
            },
            {
              name: "电脑打开",
            },
            {
              name: "将视频下载到本地",
            },
            {
              name: "释放手机空间",
            },
            {
              name: "视频上传",
            },
            {
              name: "联系我们",
            },
          ],
        },
        {
          name: "隐私",
          value: 7,
          state: false,
          content: [
            {
              name: "隐私泄露",
            },
            {
              name: "个人资料",
            },
          ],
        },
      ],
      listBoxState:true,
      scaleVal:1,
    };
  },
  mounted() {
    window.scrollTo(0,0);
     let that = this, timeId;
    document.getElementById('explain-center-right').addEventListener(
      "scroll",
      () => {
          
        clearTimeout(timeId);
        
        timeId = setTimeout(() => {
          this.scrollScreen();
          console.log("执行完了哦");
        }, 100);
      },
      true
    );
    
    this.$erd.listenTo(document.getElementById("content"), function () {
      //let dom = document.getElementById("container");
      let screenWidth = document.body.clientWidth;
      //let screenHeight = element.clientHeight;
      let scaleX = screenWidth / 1920;
      that.scaleVal = scaleX;
      //let scaleFunc = "scale(" + scaleX + "," + scaleX + ")" + "translateZ(0)";
      //element.style.transform = scaleFunc;
      //element.style.transformOrigin = "left top";
      that.$nextTick(() => {
        // dom.style.width = `${screenWidth * scaleX}px`
        //dom.style.height = `${screenHeight * scaleX }px`;
      });
    });
  },
  methods: {
    changeMenuList(index, index2) {console.log('11')
      
      this.list = this.list.filter((litem, lindex) => {
        if (index == lindex) {
          litem.state = true;
        } else {
          litem.state = false;
        }
        return litem;
      });
      this.activeIndex = index2;
      this.$refs[`page-${index + 1}-${index2 + 1}`].scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState = false;
      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState=true;
      },2000);
    },
    scrollScreen() {
      // 获取视窗高度
      let domHight = document.documentElement.clientHeight || document.body.clientHeight;
      let windowH = window.screen.availHeight;
      let containerSrollTop = this.$refs['explain-center-right'].scrollTop;//内容盒子
      let containerSrollHeight = this.$refs['explain-center-right'].scrollHeight;//内容盒子
      console.log(containerSrollTop,containerSrollHeight,domHight,windowH)

      if (this.listBoxState) {
        //作用是点击导航栏时，不执行。
        this.$nextTick(() => {
          for (let i = 0; i < this.list.length; i++) {
              for(let j = 0; j < this.list[i].content.length; j ++){
                  let eleName = `page-${i+1}-${j+1}`,
                      ele = this.$refs[eleName],
                      offsetTop = ele.offsetTop;
                console.log(i,j,offsetTop,'offsetTop')
                if(containerSrollTop == 0){
                    this.restArray();
                    this.list[0].state = true;
                    this.list.splice(0,1,this.list[0]);
                    this.activeIndex = 0;
                }
                
                if(offsetTop - containerSrollTop < (domHight/this.scaleVal-100)){
                    this.restArray();
                    this.list[i].state = true;
                    this.list.splice(i,1,this.list[i]);
                    this.activeIndex = j;
                    
                }
                
                if(containerSrollTop + windowH >= containerSrollHeight){
                    this.restArray();
                    this.list[i].state = true;
                    this.list.splice(i,1,this.list[i]);
                    this.activeIndex = this.list[i].content.length-1;
                    
                }
              }
          }
        });
      }
    },
    restArray(){
        for(let item of this.list){
            item.state = false;
        }
    },
  },
};
</script>
<style lang="scss">
.el-collapse {
  border: none;
}
.el-collapse-item__header {
  height: 80px;
  padding-left: 43px;
  font-size: 22px;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 80px;
  border: none;
}
</style>
<style scoped lang="scss">
.explain-box {
  width: 100%;
  height: 1403px;
  padding-left: 12%;
  box-sizing: border-box;
  .img1 {
    width: 384px;
    display: block;
    margin: 150px 0 47px 0;
  }
  .active {
    background: #eeeeee;
    border-right: 4px solid #dc4707;
  }
  .explain-center {
    width: 1365px;
    height: 1200px;
    overflow: hidden;
    display: flex;
    .explain-center-left {
      width: 454px;
      height: 100%;
      padding: 43px 0;
      box-sizing: border-box;
      background: #fff;
      overflow: auto;
      ul {
        li {
          height: 80px;
          font-size: 22px;
          color: #4d4d4d;
          line-height: 80px;
          padding: 0 43px 0 90px;
        }
      }
    }
    .explain-center-right {
      width: 881px;
      height: 100%;
      padding: 65px 46px 0 52px;
      margin-left: 30px;
      background: #fff;
      box-sizing: border-box;
      overflow: auto;
      h5,
      h6,
      p {
        color: #4d4d4d;
        font-family: PingFang SC-Light, PingFang SC;
      }
      h6,
      p {
        margin-bottom: 50px;
      }
      h5 {
        font-size: 48px;
        line-height: 67px;
        margin-bottom: 41px;
        padding-bottom: 23px;
        font-weight: 300;
        border-bottom: 1px solid #e0dfe1;
      }
      h6 {
        font-size: 22px;
        font-weight: 500;
      }
      p {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
</style>